<template>
  <PageFooter
    :nav-footer-items="navFooterItems"
    :nav-legal-items="navLegalItems"
    :nav-legal-line-1="$globalPageSettings?.navLegalLine1 ?? ''"
    :nav-legal-line-2="$globalPageSettings?.navLegalLine2 ?? ''"
    :nav-social="navSocialItems"
    :responsive-font-sizes="true"
    :newsletter-registration="newsletterRegistrationItems"
    :tagline="tagline"
    @submit-newsletter="onNewsletterRegistration"
  >
    <template #region-language-selector>
      <div class="theme-dark">
        <div :class="$style.regionSelectorOpenModalButton">
          <LinkElement
            style-type="primary"
            :title="$textDictionary['pageFooter.languageSelector.chooseRegion']"
            :link="{
              uri: {
                // without the path the link does not work on the homepage
                path: '.',
                hash: '#regionselector'
              }
            }"
          >
            <!-- button icon not working -->
            <Icon :class="$style.iconPadding" :scale-factor="2" name="globe" />
            {{ geoinformationStore.currentRegionLabel }}
          </LinkElement>
        </div>
        <RegionSelector />
      </div>
    </template>
  </PageFooter>
</template>

<script setup lang="ts">
import {
  Icon,
  LinkElement,
  PageFooter
} from '@hypercodestudio/basler-components';
import type { NavigationItemInterface } from '@hypercodestudio/basler-components/dist/models/navigation/NavigationItem';
import type { NewsletterRegistrationInterface } from '@hypercodestudio/basler-components/dist/components/layout/Footer/NewsletterRegistration.vue';
import type {
  NavSocialItemInterface,
  Props as NavSocialInterface
} from '@hypercodestudio/basler-components/dist/components/layout/Footer/NavSocial.vue';
import RegionSelector from '~/components/lib/RegionSelector.vue';
import { useCreateNavItem } from '~/composables/useCreateMainNavItem';
import { isDefined } from '~/utils/guards/isDefined';
import { useGeoinformationStore } from '~/stores/geoinformationStore/geoinformationStore';

const locale = useLocale();
const { $globalPageSettings, $textDictionary } = useNuxtApp();
const router = useRouter();
const geoinformationStore = useGeoinformationStore();

const navSocialItems = computed<NavSocialInterface>(() => ({
  title: $globalPageSettings.value?.socialMediaHeadline ?? '',
  text: $globalPageSettings.value?.socialMediaText ?? '',
  items: $globalPageSettings.value?.companySocialMediaLinksCollection?.items
    ?.map<NavSocialItemInterface | undefined>((item) => {
      if (!item) {
        return undefined;
      }
      const linkUri = item.externalUrl
        ? item.externalUrl
        : item.internalPage
        ? useBuildLinkInterface(item.internalPage)
        : '#';

      return {
        iconName: item.linkIcon ?? '',
        text: item.linkText ?? '',
        link: {
          uri: linkUri,
          external: item?.linkTarget === '_blank',
          target: item?.linkTarget ?? undefined
        }
      } as NavSocialItemInterface;
    })
    ?.filter(isDefined)
}));

const navFooterItems = computed<NavigationItemInterface[]>(
  () =>
    $globalPageSettings.value?.footerNavigation?.navigationItemsCollection?.items
      .map((item) => useCreateNavItem(item))
      .filter(isDefined) ?? []
);

const navLegalItems = computed<NavigationItemInterface[]>(
  () =>
    $globalPageSettings.value?.footerLegalNavigation?.navigationItemsCollection?.items
      .map((item) => useCreateNavItem(item))
      .filter(isDefined) ?? []
);

const newsletterRegistrationItems = computed<NewsletterRegistrationInterface>(
  () => ({
    copy: $globalPageSettings.value?.newsletterCopy ?? '',
    headline: $globalPageSettings.value?.newsletterHeadline ?? '',
    placeholder: $globalPageSettings.value?.newsletterPlaceholder ?? ''
  })
);

const tagline = {
  type: 'image',
  src: '/images/demo/the-power-of-sight.svg',
  alt: 'The Power of Sight',
  width: 236,
  height: 24
};

const newsletterRegistrationLink = $globalPageSettings.value
  ?.newsletterTargetPage?.metadata?.slug
  ? buildLinkInterface(
      buildUrlString(
        locale.value,
        $globalPageSettings.value?.newsletterTargetPage?.metadata?.slug
      ),
      locale.value
    )
  : null;

const onNewsletterRegistration = (email: string) => {
  const isEmail = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  if (
    !newsletterRegistrationLink ||
    !isEmail ||
    !newsletterRegistrationLink.uri
  ) {
    return;
  }

  router.push({
    path: newsletterRegistrationLink.uri.toString(),
    query: { email }
  });
};
</script>

<style module>
.regionSelectorOpenModalButton button {
  font-weight: 400;
  padding: 5px 13px;
}

.iconPadding {
  /* copied from hypercodes region selector */
  margin-inline-end: max(0.25em, 6px);
}
</style>
